<template>
    <b-container >
        <b-col md="6" offset-md="3">
            <b-modal id="updateModel" size="lg" title="Update Company" @hidden="reset">
                <form @submit.prevent="updateCompany" @reset.prevent="closeModal">
                    <div class="row">
                        <div class="col-md-12 " v-if="company">

                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="sections-one">
                                        <h5 class="heading-style"> Trial Days </h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Trial Days</label>
                                                    <b-form-input type="number" v-model="form.trial_days" placeholder="Enter Days"
                                                                  :disabled="company.subscription_status === 'expired'"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group" v-if="company.subscription_status === 'expired'">
                                                    <label>Extend Trial Days By</label>
                                                    <b-form-input type="number" v-model="form.extend_trial_days" placeholder="Enter Days"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sections-two">
                                        <h5 class="heading-style"> Premium  Subscription Quantity</h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Subscription Quantity</label>
                                                    <b-form-input type="number" v-model="form.subscription_quantity" placeholder="Enter subscription quantity"
                                                    ></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="sections-three">
                                        <h5 class="heading-style"> Premium Plan Amount Section </h5>
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="form-group">
                                                    <label>Monthly Amount</label>
                                                    <b-form-input type="number" v-model="form.monthly_amount"
                                                                  placeholder="Enter Amount"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                                <div class="form-group">
                                                    <label>Yearly Amount</label>
                                                    <b-form-input type="number" v-model="form.yearly_amount"
                                                                  placeholder="Enter Amount"></b-form-input>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    <div class="sections-four">

                                        <h5 class="heading-style"> Premium Plan Allowed Details </h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Companies</label>
                                                    <b-form-input type="number" v-model="form.companies"
                                                                  placeholder="Enter Companies"></b-form-input>
                                                </div>

                                                <div class="form-group">
                                                    <label>Team Members</label>
                                                    <b-form-input type="number" v-model="form.team_size"
                                                                  placeholder="Enter Team Members"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                                <div class="form-group">
                                                    <label>Projects</label>
                                                    <b-form-input type="number" v-model="form.projects"
                                                                  placeholder="Enter Projects"></b-form-input>
                                                </div>

                                                <div class="form-group">
                                                    <label>Data storage</label>
                                                    <b-form-select v-model="form.data_storage" :options="options"></b-form-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="sections-five mb-6">

                                        <div> Select All

                                            <input type="checkbox" v-model="testingCheck" @click='testingAllCheck(data)'>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-md-5 offset-md-1">


                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is real Time Monitoring allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.real_time_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Time Tracking allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.time_track_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Add work task allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.work_task_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>


                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Daily Work Video allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.daily_work_video_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Remove Idle Screenshots</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.remove_ideal_screenshot_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Per-user setting allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.per_user_setting_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is On-premise-deployment allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.on_premise_deployment_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Premium APP allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.premium_app_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>


                                            </div>
                                            <div class="col-md-5">
                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Offline Activity Traffic allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.offline_traffic_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is 24/7 customer Support allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.custome_support_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Instant Screen Capture allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.instant_screen_capture_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>                                                    </b-col>
                                                </b-row>


                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is KeyStrokes-mouse activity allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.keystrokes_mouse_activity_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>


                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Record & assign allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.record_maintain_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>


                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Intelligence report allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3">
                                                        <b-form-checkbox v-model="data.intelligence_report_allowed" @change='updateCheckall(data)' class="alignment" switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col md="9" sm="9" cols="9">
                                                        <label class="">Is Custom integration allowed</label>
                                                    </b-col>
                                                    <b-col md="3" sm="3" cols="3" class="fix_checkbox">
                                                        <b-form-checkbox v-model="data.custom_integration_allowed" @change='updateCheckall(data)' class="alignment"  switch></b-form-checkbox>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div slot="modal-footer" class="col-md-12 text-center">
                            <button type="submit" class="btn btn-primary m-2">Update</button>
                            <button type="reset" class="btn btn-secondary m-2">Cancel</button>
                        </div>
                    </div>
                </form>
            </b-modal>
        </b-col>
    </b-container>

</template>

<script>
    export default {
        name: "CompanyUpdate",
        data() {
            return {
                form: {
                    trial_days: 10,
                    extend_trial_days: 0,
                    monthly_amount: 14.99,
                    yearly_amount: 13.49,
                    data_storage: 30,
                    team_size: 1,
                    companies: 1,
                    projects: 1,
                    subscription_quantity:2
                },

                data:{
                    real_time_allowed: false,
                    time_track_allowed: false,
                    offline_traffic_allowed: false,
                    custome_support_allowed: false,
                    work_task_allowed: false,
                    instant_screen_capture_allowed: false,
                    keystrokes_mouse_activity_allowed: false,
                    daily_work_video_allowed: false,
                    remove_ideal_screenshot_allowed: false,
                    record_maintain_allowed: false,
                    intelligence_report_allowed: false,
                    per_user_setting_allowed: false,
                    on_premise_deployment_allowed: false,
                    custom_integration_allowed: false,
                    premium_app_allowed: false,
                },

                count:null,
                company: null,
                testingCheck:false,

                data_storage: null,
                options: [
                    { value: null, text: "Please select an option" },
                    { value: "30", text: "30 Days" },
                    { value: "1", text: "1 Year" }
                ]
            };
        },
        mounted() {
            this.$root.$on("selected_company", company => {

                this.form.trial_days = company.trial_days;
                this.form.subscription_quantity = company.subscription_quantity;
                this.form.monthly_amount = company.monthly_amount;
                this.form.yearly_amount = company.yearly_amount;
                this.data.real_time_allowed = company.real_time_allowed;
                this.form.companies = company.companies;
                this.form.projects = company.projects;
                this.form.team_size = company.team_size;
                this.data.time_track_allowed = company.time_track_allowed;
                this.data.offline_traffic_allowed = company.offline_traffic_allowed;
                this.data.custome_support_allowed = company.custome_support_allowed;
                this.data.work_task_allowed = company.work_task_allowed;
                this.form.screenshots_allowed = company.screenshots_allowed;
                this.data.instant_screen_capture_allowed =
                    company.instant_screen_capture_allowed;
                this.data.keystrokes_mouse_activity_allowed =
                    company.keystrokes_mouse_activity_allowed;
                this.data.daily_work_video_allowed = company.daily_work_video_allowed;
                this.data.remove_ideal_screenshot_allowed =
                    company.remove_ideal_screenshot_allowed;
                this.data.record_maintain_allowed = company.record_maintain_allowed;
                this.data.intelligence_report_allowed =
                    company.intelligence_report_allowed;
                this.data.per_user_setting_allowed = company.per_user_setting_allowed;
                this.data.on_premise_deployment_allowed =
                    company.on_premise_deployment_allowed;
                this.data.custom_integration_allowed = company.custom_integration_allowed;
                this.data_storage = company.data_storage;
                this.company = company;
            });

        },

        methods: {
            updateCompany() {
                let data = {
                    ...this.form,
                    ...this.data,
                    company_id: this.company.hash_id
                };
                this.$store.commit("toggle_loader", true);
                this.$store
                    .dispatch("updateCompany", data)
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                        this.$notify("Success!", response.data.error_msg, "success");
                        // this.$parent.getDashboardData();
                        // if (!response.data.error) alert(response.data.error_msg);
                        // this.closeModal();
                    })
                    .catch(error => {
                        this.$store.commit("toggle_loader", false);
                        if (error.response.status === 422 && error.response.data.message)
                            this.$notify("Error!", Object.values(error.response.data.errors)[0][0], "danger");

                    });
            },
            closeModal() {
                this.$root.$emit("bv::hide::modal", "updateModel");
            },
            reset() {
                this.form = {
                    trial_days: 10,
                    extend_trial_days: 0,
                    monthly_amount: 14.99,
                    yearly_amount: 13.49,
                    real_time_allowed: false,
                    time_track_allowed: false,
                    offline_traffic_allowed: false,
                    custome_support_allowed: false,
                    work_task_allowed: false,
                    screenshots_allowed: false,
                    instant_screen_capture_allowed: false,
                    keystrokes_mouse_activity_allowed: false,
                    daily_work_video_allowed: false,
                    remove_ideal_screenshot_allowed: false,
                    record_maintain_allowed: false,
                    intelligence_report_allowed: false,
                    per_user_setting_allowed: false,
                    on_premise_deployment_allowed: false,
                    custom_integration_allowed: false,
                    premium_app_allowed: false,
                    data_storage: 30,
                    team_size: 1,
                    companies: 1,
                    projects: 1
                };
            },
            getlength(data) {
                setTimeout(() => {
                    var testing = Object.keys(data).length;
                    var truecount = 0;
                    Object.keys(data).forEach((v) => (data[v] === true && truecount++));
                    if (truecount == testing) {
                        this.testingCheck = true;
                    } else {
                        this.testingCheck = false;
                    }

                });
            },

            updateCheckall(data) {
                this.getlength(data);
            },

            testingAllCheck(data) {

                if (this.testingCheck == false) {
                    this.change(data);
                } else {
                    this.unchange(data);
                }
            },

            change(data) {

                Object.keys(data).forEach(function (key) {
                    data[key] = true
                });
                return data;
            },

            unchange(data) {
                Object.keys(data).forEach(function (key) {
                    data[key] = false
                });
                return data;
            }

        }
    };
</script>

<style scoped>
    .alignment {
        left: 5%;

    }

    ul {
        list-style: none
    }
    input {
        margin-right: .5em;
    }

    .label-setting{
        width: 172px;
    }
    .sections-five{
        margin-bottom: 30px;
    }

    .heading-style{
        font-size: 15px !important;
    }
</style>
